import Vue from 'vue';

const codeMessagesMap = {
  200: 'Applied successfully',
  201: 'Created successfully',
};

const Fetching = {
  install(Vue) {
    // show snackbar after success/failed requests
    Vue.prototype.$processReq = function (
      promise,
      notify_on_ok = true,
      notify_on_err = true,
    ) {
      this.processing = true;
      return promise
        .then((res) => {
          if (notify_on_ok) {
            let message = codeMessagesMap[200];
            if (res.data.message) {
              message = res.data.message;
            } else if (codeMessagesMap[res.status]) {
              message = codeMessagesMap[res.status];
            }

            this.$root.$emit('snackbar', {
              text: message,
              color: 'success',
            });
          }
          return res;
        })
        .catch(this.$processReqError)
        .catch((err) => {
          if (err.response && err.response.code === 423) {
            this.is_locked = true;
            this.is_locked_message =
              err.response.data && err.response.data.message;
          }
          if (notify_on_err) {
            let text = err.message;
            if (err.response && err.response.data.message) {
              text = err.response.data.message;
            } else {
              for (let prop in err.response.data) {
                if (err.response.status === 500) {
                  text = 'Something went wrong.';
                } else if (
                  err.response.data &&
                  err.response.data[prop] &&
                  Array.isArray(err.response.data[prop]) &&
                  err.response.data[prop].length &&
                  typeof err.response.data !== 'string'
                ) {
                  console.log(
                    err.response.data[prop],
                    'err.response.data[prop]',
                  );
                  err.response.data[prop].forEach((obj) => {
                    if (obj.message) {
                      text = obj.message[0];
                    }
                  });
                }
              }
            }

            this.$root.$emit('snackbar', { text, color: 'error' });
          }
          throw err;
        })
        .finally(() => {
          this.processing = false;
        });
    };
    Vue.prototype.$processReqSilent = function (promise) {
      this.processing = true;
      return promise.catch(this.$processReqError).finally((...args) => {
        this.processing = false;
        return args;
      });
    };
    Vue.prototype.$processReqError = function (err) {
      if (
        err.response &&
        (err.response.code === 401 || err.response.status === 401)
      ) {
        Vue.prototype.$logout();
      }
      throw err;
    };
    Vue.prototype.$cache = {
      payment_request: { list: null, create: null, deleted_list: null },
    };
    Vue.mixin({
      data: () => ({
        processing: false,
        is_locked: false,
        is_locked_message: '',
      }),
    });
  },
};

Vue.use(Fetching);
