const state = {
  renewalDate: '',
  sections: null,
};
const getters = {
  getRenewalDate: (state) => {
    return state.renewalDate;
  },
  getSections: (state) => {
    return state.sections;
  },
};

const actions = {
  setRenewalDate({ commit }, value) {
    commit('setRenewalDate', value);
  },
  setSections({ commit }, value) {
    commit('setSections', value);
  },
};

const mutations = {
  setRenewalDate(state, value) {
    state.renewalDate = value;
  },
  setSections(state, value) {
    state.sections = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
