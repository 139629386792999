import Vue from 'vue';
import Vuetify from 'vuetify';
// import 'vuetify/src/stylus/app.styl'
import { Ripple } from 'vuetify/lib/directives';

import 'vuetify/dist/vuetify.min.css';
import ShopifyIcon from '../components/icons/ShopifyIcon';

Vue.use(Vuetify, {
  iconfont: 'md',
  directives: {
    Ripple,
  },
  theme: {
    primary: '#f77c1a',
  },
  icons: {
    shopify: {
      component: ShopifyIcon, // you can use string here if component is registered globally
      props: {
        // pass props to your component if needed
        name: 'shopify',
      },
    },
  },
});
