<template>
  <vue-app>
    <Dashboard v-if="!noNavBar && ready()" />
    <SnackBar />
    <SessionModal v-if="!noNavBar" :isIdle="isIdle" />
    <Version />
  </vue-app>
</template>

<script>
import Vue from 'vue';

import Dashboard from './components/Dashboard.vue';
import SessionModal from './components/SessionModal.vue';

import SnackBar from '@/components/SnackBar';
import Version from '@/components/Version';

export default {
  components: { Dashboard, SnackBar, SessionModal, Version },
  methods: {
    ready() {
      return Vue.prototype.$config;
    },
  },
  computed: {
    noNavBar() {
      return (
        Vue.prototype.$noNavBar ||
        this.$route.name === 'login' ||
        this.$route.name === 'forget-password'
      );
    },
    isIdle() {
      return this.$store.state.idleVue?.isIdle || false;
    },
  },
};
</script>
