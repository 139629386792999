import './set-public-path';
import './plugins/fetching';
import './assets/css/style.css';
import './assets/css/main.css';
import './assets/css/common.css';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
// import { BrowserTracing } from "@sentry/tracing";
// import * as SentryVue from "@sentry/vue";
import IdleVue from 'idle-vue';
import json5 from 'json5';
import singleSpaVue from 'single-spa-vue';
import VueSkeletonLoader from 'skeleton-loader-vue';
import Vue from 'vue';

import pjson from '../package.json';

import App from './App.vue';
import { logout, getToken, refreshToken } from './lib/auth';
import { i18n } from './plugins/i18n';
import router from './router';
import store from './store';
import './plugins/vuetify';

Vue.config.productionTip = false;
const eventsHub = new Vue();
let user = Vue.observable({ data: {} });
Object.defineProperty(Vue.prototype, '$user', {
  get() {
    return user.data;
  },
  set(value) {
    user.data = value;
  },
});
const n_ws_notify = Vue.observable({ n_ws_notify: {} });
Object.defineProperty(Vue.prototype, '$n_ws_notify', {
  get() {
    return n_ws_notify.n_ws_notify;
  },
  set(value) {
    n_ws_notify.n_ws_notify = value;
  },
});

Vue.component('vue-skeleton-loader', VueSkeletonLoader);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {},
      });
    },
    created: async function () {
      Vue.prototype.$config = {};
      Vue.prototype.$isKeycloakAuth = this.isKeycloakAuth;
      Vue.prototype.$isMerchantAdmin = this.isMerchantAdmin;
      if (this.isKeycloakAuth) {
        Vue.prototype.$token = this.idToken;
        Vue.prototype.$refreshToken = this.refreshToken;
        Vue.prototype.$logout = this.logout;
        Vue.prototype.$keycloakDomain = this.keycloakDomain;
        Vue.prototype.$merchant = this.merchant;
        if (this.idToken()) {
          this.setUserDetails(
            this.sharedMFState.user,
            this.sharedMFState.config,
            this.sharedMFState.sections,
          );
        }
      } else {
        Vue.prototype.$token = getToken;
        Vue.prototype.$logout = logout;
        Vue.prototype.$refreshToken = refreshToken;
      }
      Vue.prototype.$noNavBar = this.noNavBar;
      Vue.prototype.$serverOrigin = this.serverOrigin;
      Vue.prototype.$sentryUrl = this.sentryUrl;
      const lang = this.$route.path.slice(1, 3);
      if (lang === 'en' || lang === 'ar') {
        this.$i18n.locale = lang;
      }
      this.setLang();
      this.$vuetify.rtl = this.$i18n.locale === 'ar';
      this.$vuetify.theme['nfo-main-color'] = 'F77C1A';
      this.$vuetify.theme['fo-main-color'] =
        this.foodOrderingColor || '#f77c1a';
      Sentry.setTag('microfrontend', 'navigation');
      Sentry.init({
        dsn: this.sentryUrl,
        integrations: [
          new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
          //   new BrowserTracing({
          //     tracePropagationTargets: [
          //       localStorage.getItem("MERCHANT_ID"),
          //       /^\//
          //     ],
          //     routingInstrumentation: SentryVue.vueRouterInstrumentation(router)
          //   })
        ],
        release: `navigation@${pjson.version}`,
        // tracesSampleRate: this.traceSampleRate,
        ignoreErrors: this.ignoredSentryErrors,
        beforeSend: this.checkSentryErrors,
        sampleRate: this.sentrySampleRate,
        autoSessionTracking: false,
        enabled: !this.isLocal,
      });
    },
    computed: {
      ws_notification() {
        return this.ws_notification;
      },
    },
    watch: {
      '$i18n.locale': function () {
        this.$vuetify.rtl = this.$i18n.locale === 'ar';
      },
      ws_notification: {
        immediate: true,
        handler() {
          Vue.set(Vue.prototype, '$n_ws_notify', this.ws_notification);
          if (this.ws_notification.length) {
            this.showMessage();
          }
        },
      },
      'sharedMFState.isLoadingConfig': function () {
        if (this.sharedMFState.isLoadingConfig === false)
          this.setUserDetails(
            this.sharedMFState.user,
            this.sharedMFState.config,
            this.sharedMFState.sections,
          );
        else {
          this.sharedMFState.config = null;
          this.sharedMFState.user = null;
          this.sharedMFState.sections = null;
        }
      },
    },
    methods: {
      setLang() {
        const translations = this.translations();
        this.$i18n.setLocaleMessage('en', translations.en);
        this.$i18n.setLocaleMessage('ar', {
          ...this.arabicTranslation,
          ...translations.ar,
        });
      },
      showMessage() {
        let event =
          json5.parse(
            this.ws_notification.find((ws_notification) => ws_notification),
          ) || {};
        if (event?.content?.message || event?.content?.data?.file) {
          const msText =
            event.content.message || this.$t('Your download is ready');
          this.$root.$emit('snackbar', {
            text: msText,
            color: event.content.alert,
            link: event.content.data?.file,
            url: event.content.data?.url,
          });
        }
      },
      async setUserDetails(user, config, sections) {
        Vue.prototype.$config = config;
        Vue.prototype.$user = user;
        const idletime = !config.enable_session_timeout
          ? 24 * 60 * 60 * 1000
          : localStorage.getItem('VUE_APP_SESSION_IDLE') || 1200000;
        Vue.use(IdleVue, {
          eventEmitter: eventsHub,
          store,
          idleTime: idletime,
          startAtIdle: false,
        });
        Vue.set(
          Vue.prototype,
          '$enableSessionTimeout',
          config.enable_session_timeout,
        );

        store.commit('dashboard/setRenewalDate', config.renewal_date);
        store.commit('dashboard/setSections', sections);
      },
    },
    router,
    i18n,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
